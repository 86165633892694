<script lang="ts" setup>
const { isOpen, close, target } = useNavigationMobileModal()
</script>
<template>
  <Teleport to="body">
    <div v-if="isOpen" ref="target" class="modal modal-navigation mobile-only" @keyup.esc="close">
      <NavigationMobile />
    </div>
  </Teleport>
</template>
<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--c-white);

  z-index: 100;
}
</style>
